import createGlobe from "cobe";
import { useEffect, useRef } from "react";
import { Container } from "../LandingPage/Container";
import { useMediaQuery } from "react-responsive";
import cx from "classix";

type Props = {
  recordAnalyticsCount: number;
  recordsCount: number;
  globeMarkers: { location: [number, number]; size: number }[];
};

// https://github.com/shuding/cobe
export default function GlobeWithStats(props: Props) {
  const { recordAnalyticsCount, recordsCount, globeMarkers } = props;
  const canvasRef = useRef();

  const isSmall = useMediaQuery({ query: "(max-width: 1000px)" });
  const isXSmall = useMediaQuery({ query: "(max-width: 700px)" });
  const width = isSmall ? 700 : 1000;
  const height = width;

  useEffect(() => {
    let phi = 0;

    if (!canvasRef.current) {
      return;
    }

    const globe = createGlobe(canvasRef.current, {
      devicePixelRatio: 2,
      width: width * 2,
      height: height * 2,
      phi: 0,
      theta: 0,
      dark: 1,
      diffuse: 1.2,
      mapSamples: 16000,
      mapBrightness: 6,
      baseColor: [80 / 255, 79 / 255, 127 / 255],
      markerColor: [153 / 255, 91 / 255, 217 / 255],
      glowColor: [80 / 255, 79 / 255, 127 / 255],
      markers: globeMarkers,
      onRender: (state) => {
        // Called on every animation frame.
        // `state` will be an empty object, return updated params.
        state.phi = phi;
        phi += 0.001;
      },
    });

    return () => {
      globe.destroy();
    };
  }, [isSmall, isXSmall]);

  return (
    <div className="w-full flex flex-col items-center relative h-[500px]">
      {!isXSmall && (
        <canvas
          //   @ts-ignore
          ref={canvasRef}
          style={{
            width,
            height,
            maxWidth: "100%",
            aspectRatio: 1,
            marginTop: 0,
          }}
        />
      )}
      <div
        className={cx(
          "w-full  bg-[#7030ff29] cobe-backdrop absolute",
          isXSmall ? "h-full pb-32 mt-32" : "h-[200px] top-[300px]"
        )}
      >
        <Container
          className={cx(
            "grid h-full max-w-7xl",
            isXSmall ? "grid-cols-1" : "grid-cols-2"
          )}
        >
          {[
            {
              stat: `${Math.round(recordAnalyticsCount / 1000)}k`,
              title: "requests",
            },
            {
              stat: `${(recordsCount / 1000).toFixed(1)}k`,
              title: "records",
            },
          ].map(({ stat, title }: { stat: string; title: string }) => (
            <div
              key={title}
              className="flex items-center flex-col justify-center w-full h-full"
            >
              <span
                className={cx(
                  "gradient-text font-bold",
                  isXSmall ? "text-6xl" : "text-7xl"
                )}
              >
                {stat}
              </span>
              <span className="text-white mt-4 text-1xl font-bold">
                {title}
              </span>
            </div>
          ))}
        </Container>
      </div>
    </div>
  );
}

import Head from "next/head";

import { Faqs } from "@/components/LandingPage/Faqs";
import { Footer } from "@/components/LandingPage/Footer";
import { Header } from "@/components/LandingPage/Header";
import { Hero } from "@/components/LandingPage/Hero";
import { Pricing } from "@/components/LandingPage/Pricing";
import { PrimaryFeatures } from "@/components/LandingPage/PrimaryFeatures";
import { SecondaryFeatures } from "@/components/LandingPage/SecondaryFeatures";
import { UseCases } from "@/components/LandingPage/UseCases";
import { useEffect } from "react";
import { useRouter } from "next/router";
import analytics from "@/utils/analytics/analytics";
import GlobeWithStats from "@/components/GlobeWithStats/GlobeWithStats";
import { Container } from "@/components/LandingPage/Container";
import { Integration } from "@/components/Integration";
import { getStatsForLandingPage } from "@/controllers/stats";
import { InferGetStaticPropsType } from "next";

export default function Home(
  props: InferGetStaticPropsType<typeof getStaticProps>
) {
  const { recordsAnalyticsCount, recordsCount, globeMarkers } = props;

  useEffect(() => {
    analytics.track("view_marketing_page");
  }, []);

  // TODO
  // COnnecter paiement
  // Upgrade compte via page billing
  // Erreur lighthouse

  return (
    <div className="bg-[#1E1E1E]">
      <Head>
        <title>myJson - JSON Storage Online</title>
      </Head>

      <div className="hero-radial-background">
        <Header dark={false} />
        <Hero />
        <div className="-mt-32">
          <GlobeWithStats
            recordAnalyticsCount={recordsAnalyticsCount}
            recordsCount={recordsCount}
            globeMarkers={globeMarkers}
          />
        </div>
      </div>
      <div className="landing-page-diamond-gradient">
        <PrimaryFeatures />
        <UseCases />
        <Pricing />
      </div>
      {/* <Integration /> */}
      {/* <Faqs /> */}
      <Footer />
    </div>
  );
}

export async function getStaticProps() {
  const { recordsAnalyticsCount, recordsCount, globeMarkers } =
    await getStatsForLandingPage();
  return {
    props: {
      recordsAnalyticsCount,
      recordsCount,
      globeMarkers,
    },
    revalidate: 1800, // 1800 seconds (30 minutes)
  };
}
